import React from "react";
import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import ru from "react-phone-input-2/lang/ru.json";

import colors from "../../../core/styles/colors";

const StyledPhoneInput = styled(ReactPhoneInput)<{ hasErrors?: boolean }>`
  .form-control {
    position: relative;
    width: 100%;
    height: 48px;
    padding-right: 13px;

    font-family: "Gilroy", serif;
    font-size: 18px;
    font-weight: 300;
    color: ${colors.black.general};
    outline: none;
    transition: ease-in-out 0.3s;

    border-radius: 10px;
    border: 2px solid
      ${(props: { hasErrors?: boolean }): string =>
        props.hasErrors ? colors.red.general : colors.white.hoverWhite3};
    background: ${colors.white.hoverWhite3};

    &:focus {
      border: 2px solid
        ${(props: { hasErrors?: boolean }): string =>
          props.hasErrors ? colors.red.general : colors.gray.gray200};
    }

    &::placeholder {
      color: ${colors.gray.gray300};
    }

    &:disabled {
      cursor: not-allowed;
      border-color: ${colors.white.disabled};
      background: ${colors.white.disabled};

      &::placeholder {
        color: ${colors.white.disabledText};
      }
    }
  }

  .flag-dropdown {
    transition: ease-in-out 0.3s;
    background: transparent;
    margin: 2px;
    border: none;

    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    &.open {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      background: ${colors.white.hoverWhite4};

      .selected-flag {
        background: transparent !important;
      }
    }
  }

  .selected-flag {
    transition: ease-in-out 0.3s;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    &:hover {
      background: ${colors.white.hoverWhite4};
    }
  }

  .country-list {
    border-radius: 10px;

    .search {
      padding: 10px;

      .search-box {
        margin: 0 !important;
        width: 100%;
      }
    }

    .country {
      padding: 14px 10px;
    }
  }
`;

type TPhoneInputProps = PhoneInputProps & {
  hasErrors?: boolean;
};

const PhoneInput: React.FC<TPhoneInputProps> = ({
  localization = ru,
  hasErrors = false,
  enableSearch = true,
  disableSearchIcon = true,
  countryCodeEditable = false,
  country = "ru",
  placeholder = "+7 (900) 000-20-40",
  searchPlaceholder = "Поиск...",
  searchNotFound = "Страна и код не найдены",
  preferredCountries = ["ru", "by", "kz"],
  ...props
}) => {
  return (
    <StyledPhoneInput
      autoFormat
      localization={localization}
      hasErrors={hasErrors}
      enableSearch={enableSearch}
      disableSearchIcon={disableSearchIcon}
      countryCodeEditable={countryCodeEditable}
      country={country}
      placeholder={placeholder}
      searchNotFound={searchNotFound}
      searchPlaceholder={searchPlaceholder}
      preferredCountries={preferredCountries}
      {...props}
    />
  );
};

export default PhoneInput;
