const isSnils = (snils: string): string | boolean => {
  const errorMessage = "Некорректный СНИЛС";
  const cleaned = snils.replace(/\D/g, "");

  if (cleaned.length !== 11 || !/^\d+$/.test(cleaned)) {
    return errorMessage;
  }

  const mainPart = cleaned.slice(0, 9);
  const controlDigits = cleaned.slice(-2);

  if (mainPart === "000000000") {
    return errorMessage;
  }

  const sum = Array.from(mainPart)
    .map((digit, index) => parseInt(digit, 10) * (9 - index))
    .reduce((accumulator, current) => accumulator + current, 0);

  let calculatedControl;
  if (sum < 100) {
    calculatedControl = sum;
  } else if (sum === 100 || sum === 101) {
    calculatedControl = 0;
  } else {
    const remainder = sum % 101;
    calculatedControl = remainder === 100 ? 0 : remainder;
  }

  const formattedControl = calculatedControl.toString().padStart(2, "0");

  return formattedControl === controlDigits || errorMessage;
};

export default isSnils;
