const matchesMask = (phone: string, mask: string): boolean => {
  const regexPattern = mask
    .replace(/\+/g, "\\+")
    .replace(/\./g, "\\d")
    .replace(/\(/g, "\\(")
    .replace(/\)/g, "\\)");

  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(phone);
};

export default matchesMask;
