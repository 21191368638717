import styled from "styled-components";
import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import sizes from "../../../core/styles/sizes";
import colors from "../../../core/styles/colors";
import { GrayToRedButton } from "../native/button.styles";
import ImgFile from "../../../core/static/img/file.png";

export const FieldData = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .defaultInput {
    width: calc(60% - 30px);
  }

  .defaultSelect {
    width: 60%;
  }

  .defaultCalendar {
    width: 60%;
  }

  button {
    &:hover {
      svg {
        filter: brightness(0) invert(1);
      }
    }
  }

  @media all and (max-width: 1120px) {
    &:has(input[type="text"], .checkbox) {
      .checkbox {
        margin: 8px 0 0 10px;
      }
    }
  }

  @media all and (${sizes.mobile}) {
    width: 100%;
    margin-bottom: 16px;
    .defaultInput {
      width: 100%;
    }

    .defaultSelect {
      width: 100%;
    }

    .defaultCalendar {
      width: 100%;
    }
  }
`;

export const FieldText = styled.p<{ $gray?: boolean }>`
  font-family: Gilroy, serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  margin: 0 0 0 8px;
  padding: 13px 0;
  color: ${(props): string => (props.$gray ? colors.gray.gray300 : "default")};

  @media all and (${sizes.mobile}) {
    margin: 0 0 0 16px;
    padding: 0;
  }
`;

export const StylesFieldPhoto = styled.img`
  height: 80px;
  width: auto;
  border: 2px dashed ${colors.gray.gray300};
  margin-left: 16px;
  word-wrap: break-word;
`;

export const FieldPhoto: React.FC<ImgHTMLAttributes<HTMLImageElement>> = ({ src, ...props }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [src]);

  const onError = (): void => setHasError(true);

  if (hasError) {
    return (
      <a href={src} target="_blank" rel="noreferrer">
        <StylesFieldPhoto src={ImgFile} {...props} />
      </a>
    );
  }

  return <StylesFieldPhoto src={hasError ? ImgFile : src} onError={onError} {...props} />;
};

export const AddFileContainer = styled.div`
  min-width: 280px;
  width: calc(60% - 4px);
  display: flex;
  align-items: stretch;

  .addFileButton {
    margin: 0 8px 8px 0;

    button {
      padding: 0 40px;
    }
  }

  @media all and (${sizes.mobile}) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const FormDataGrayToRedButton = styled(GrayToRedButton)`
  font-weight: 300;
  text-transform: initial;
  color: ${colors.gray.gray300};
  padding: 0 13px;
`;

export const RowFieldsContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
`;
