import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import steps from "./constants/steps";
import { useAppDispatch, useAppSelector } from "../../../core/hooks/redux";
import Stepper from "../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../shared/native/button.styles";
import { ReactComponent as LineArrowIcon } from "../../../core/static/icons/line-arrow.svg";
import { setViewedStep } from "../../../store/modules/profile/profileForm/profileFormSlice";
import ProfileFormInterface from "./interfaces/ProfileForm";
import { saveProfileFormRequest } from "../../../http/requests/profile.http";
import UseAlert from "../../../core/hooks/useAlert";
import submitFormCases from "./constants/submitFormCases";
import { fetchGetProfileForm } from "../../../store/modules/profile/profileForm/profileThunk";
import getCookie from "../../../core/utils/getCookie";
import { Form, FormActions, FormContent } from "../../shared/form/form.styles";
import ResponseBody from "../../../http/models/ResponseBody";
import SavedProfileData from "./interfaces/savedProfileData";
import loading from "../../../core/types/loading";

const ProfileForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { profileFormState } = useAppSelector((state) => state.modulesState);
  const { register, control, formState, handleSubmit, reset, setValue, getValues, clearErrors } =
    useForm<ProfileFormInterface>({
      values: {
        ...profileFormState.personalData,
        ...profileFormState.contactsData,
        ...profileFormState.passportData,
        ...profileFormState.residingPlaceData,
      },
      mode: "onBlur",
    });

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedProfileStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedProfileStep)));
    }
  }, []);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedProfileStep=${step}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return steps.find((step, key) => profileFormState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = profileFormState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (
    stepId: number,
    formType: string,
    convertFunc: (data: any) => any,
    formData: any
  ): void => {
    setSubmitLoadingStatus("LOADING");

    saveProfileFormRequest(formType, convertFunc(formData)).then((saveProfileRes) => {
      if (saveProfileRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveProfileRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      alertHandler({ message: "Информация сохранена", type: "success" });
      dispatch(fetchGetProfileForm()).then((action) => {
        const getRes = action.payload as ResponseBody<SavedProfileData>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (formType === "address") {
          navigate("/profile/check-profile-form");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        submitForm(
          profileFormState.viewedStep,
          submitFormCases[profileFormState.viewedStep].name,
          submitFormCases[profileFormState.viewedStep].convertFunc,
          data
        )
      )}
    >
      <Stepper items={steps} currentStep={profileFormState.viewedStep} className="stepper" />
      <FormContent>
        {getCurrentFormComponent()({
          register,
          control,
          formState,
          reset,
          setValue,
          getValues,
          clearErrors,
        })}
      </FormContent>
      <FormActions lastStep={profileFormState.viewedStep === 3}>
        {profileFormState.viewedStep !== 0 && (
          <OutlinedButton
            disabled={profileFormState.dataLoadingStatus === "LOADING"}
            className="leftButton"
            type="button"
            onClick={goToPreviousStep}
          >
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={
            profileFormState.dataLoadingStatus === "LOADING" || submitLoadingStatus === "LOADING"
          }
          className="rightButton"
          type="submit"
        >
          {profileFormState.viewedStep !== 3 ? <LineArrowIcon /> : "Сохранить"}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default ProfileForm;
