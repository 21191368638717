import SavedProfileData from "../interfaces/savedProfileData";
import convertAddressObject from "./convertAddressObject";
import convertFile from "../../../../core/utils/convertFile";

const convertFromSavedData = (data: SavedProfileData): SavedProfileData => {
  return {
    personalData: data.personalData
      ? {
          ...data.personalData,
          gender: data.personalData.gender,
          dateOfBirth: data.personalData.dateOfBirth ?? "",
        }
      : null,
    contactsData: data.contactsData
      ? {
          ...data.contactsData,
          snils: "",
          hasNotSnils: false,
          photo: convertFile("from", data.contactsData.photo),
        }
      : null,
    passportData: data.passportData
      ? {
          ...data.passportData,
          snils: data.contactsData.snils,
          hasNotSnils: data.contactsData.hasNotSnils,
          dateOfIssue: data.passportData.dateOfIssue ?? "",
          changeFullnameDocument: convertFile("from", data.passportData.changeFullnameDocument),
        }
      : null,
    residingPlaceData: data.residingPlaceData
      ? {
          ...data.residingPlaceData,
          registerAddress: convertAddressObject("from", data.residingPlaceData.registerAddress),
          residingAddress: convertAddressObject("from", data.residingPlaceData.residingAddress),
          mainPageOfPassport: convertFile("from", data.residingPlaceData.mainPageOfPassport),
          registrationPageOfPassport: convertFile(
            "from",
            data.residingPlaceData.registrationPageOfPassport
          ),
        }
      : null,
  };
};

export default convertFromSavedData;
